/** @format */
import PLATFORM from 'aurelia-pal';
import { inlineView } from 'aurelia-framework';

@inlineView('<template><router-view containerless></router-view></template>')
export class AdminDriversPage {
  configureRouter(config) {
    config.map([
      {
        route: '',
        name: 'admin-drivers',
        title: '',
        moduleId: PLATFORM.moduleName('./admin-drivers-list')
      },
      {
        route: ':id',
        name: 'admin-driver-details',
        title: 'Driver details',
        moduleId: PLATFORM.moduleName('./admin-driver-details')
      }
    ]);
  }
}
