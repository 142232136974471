/** @format */

// import { computedFrom } from 'aurelia-framework';
import { ConvertUtils } from '@fonix/web-utils';

export class TemperatureSettings {
  constructor() {
    this.temperature = {};

    this.conditions = {
      higher: 'higher',
      lower: 'lower'
    };
    this.tempUnit = null;
  }

  activate(model) {
    this.temperature = model;

    if (this.temperature) {
      this.tempUnit = ConvertUtils.getUnit('temperature');
      this.temperature.value = this.temperature.value || 0;
      this.temperature.condition =
        this.temperature.condition || this.conditions.higher;
    }
  }
}
