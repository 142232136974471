/** @format */

import { computedFrom, NewInstance } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ValidationRules, ValidationController } from 'aurelia-validation';

import driversService from 'services/api/driversService';

import './driver-consent-dialog.scss';

export class DriverConsentDialog {
  static inject() {
    return [DialogController, NewInstance.of(ValidationController)];
  }

  constructor(_DialogController, _ValidationController) {
    this.dialogController = _DialogController;
    this.validationController = _ValidationController;
    //
    this.driversService = driversService;
    //

    this.genders = {
      unkown: 'unkown',
      male: 'male',
      female: 'female'
    };

    //email to search dvladriver for

    this.loading = false;
    this.error = null;

    this.searchEmail = null;
    this.allowSearch = true;

    this.driverId = null;
    this.dvlaDriver = null;
    this.dvlaDriverFound = false;

    //split validation rules for dvla for easy-reading
    this.rules = ValidationRules.ensure(d => d.gender)
      .satisfies(
        gender => gender === this.genders.male || gender === this.genders.female
      )
      .withMessageKey('required')

      .ensure(d => d.licenceNumber)
      .required()
      .ensure(d => d.dateOfBirth)
      .required()
      .ensure(d => d.emailAddress)
      .required()
      .email()
      //
      .ensure(d => d.address)
      .required()
      .ensure(d => d.postCode)
      .required().rules;
  }

  @computedFrom(
    'loading',
    'dvlaDriver.emailAddress',
    'dvlaDriver.phoneNumberObj.number'
  )
  get disableRequest() {
    if (!this.dvlaDriver) {
      return false;
    }
    let phonenumber = (this.dvlaDriver.phoneNumberObj || {}).number;
    return this.loading || !this.dvlaDriver.emailAddress || !phonenumber;
  }

  activate(driver) {
    if (!driver) {
      this.onCancel();
      return;
    }
    this.driverId = driver.id;

    let { name, phoneNumberObj, emailAddress, licenceNumber } = driver;
    this.defaultDriver = { name, emailAddress, licenceNumber };
    this.defaultDriver.phoneNumberObj = Object.assign(
      { countryCode: 44 },
      phoneNumberObj
    );

    this.allowSearch = !driver.dlc;
    this.searchEmail = driver.emailAddress;

    if (driver.dlc && driver.dlc.driver) {
      this.dvlaDriver = Object.assign(
        {},
        this.defaultDriver,
        driver.dlc.driver
      );
      this.dvlaDriver.phoneNumberObj = this.dvlaDriver.phoneNumberObj;
      this.dvlaDriverFound = true;
    }
  }

  onCancel() {
    this.dialogController.cancel();
  }

  onSearch() {
    this.loading = true;
    this.error = null;

    let dirtyFields = this.dvlaDriverFound ? null : this.dvlaDriver;

    this.dvlaDriver = null;
    const afterSearch = driver => {
      let found = !!driver;

      //clean when driver is found
      dirtyFields = found ? null : dirtyFields;

      this.dvlaDriverFound = found;
      this.dvlaDriver = Object.assign(
        {},
        this.defaultDriver,
        dirtyFields,
        driver,
        { emailAddress: this.searchEmail }
      );

      this.loading = false;
    };

    this.driversService
      .searchDLC(this.searchEmail)
      .then(afterSearch)
      .catch(() => {
        // console.log(err);
        afterSearch(null);
      });
  }

  onSearchSubmit(e) {
    e.preventDefault();

    this.onSearch();
  }

  validate() {
    if (this.dvlaDriverFound) {
      return Promise.resolve(true);
    }

    return this.validationController
      .validate({ object: this.dvlaDriver, rules: this.rules })
      .then(result => {
        return result.valid;
      })
      .catch(() => {
        // console.log('onValidate error', err);
        return false;
      });
  }

  onRequest() {
    this.validate().then(valid => {
      if (!valid) {
        return;
      }

      this.loading = true;
      this.error = null;

      this.driversService
        .requestConsent(this.driverId, this.dvlaDriver)
        .then(_driver => {
          this.loading = false;
          //
          this.dialogController.ok(_driver);
        })
        .catch(err => {
          this.loading = false;
          this.error = (err && err.message) || 'Something went wrong';
        });
    });
  }
}
