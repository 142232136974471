import { inject } from 'aurelia-framework';
import { DateTimeUtils as DTU } from '@fonix/web-utils';

export class ExternalpowerSettings {

  constructor() {
    this.settings = {};
  }

  activate(model) {
    this.settings = model;
    this.settings.minimumVoltage = this.settings.minimumVoltage || "10.0";
  }

  //move this to value converter
  onChange(e) {
    let v = Math.max(0, Math.min(24, e.target.value)).toFixed(1);
    this.settings.minimumVoltage = e.target.value = v
  }
}
