import { inject, bindable } from 'aurelia-framework';
import { ConvertUtils } from '@fonix/web-utils';
import { TranslationService } from 'services/translationService';
import uiService from 'services/uiService';


///
///TODO: refactor this into a <unit-select><unit-select> element.
//
@inject(TranslationService)
export class SelectOdometerUnit {

  @bindable onUnitChanged;
  @bindable unit;
  @bindable disabled;

  constructor() {
    this.uiService = uiService;

    this.units = [];
    this.accountUnit = ConvertUtils.getUnit('distance') || { name: 'km', weight: 0 };

    this.buildUnits();
    this.onSelectChanged = this.onSelectChanged.bind(this);
  }

  attached() {
    this.loadUnits();
  }

  loadUnits() {
    this.uiService.getServerUnits('distance')
      .then(units => {
        this.buildUnits(units);
        this.unitChanged(this.unit);
      })
  }

  buildUnits(units) {
    this.units = (units || []).map(u => { return { id: u.name, name: u.name, selected: false, unit: u } });
    this.units.unshift({ 
        default: true,
        id: 0,
        name: `default (${this.accountUnit.name})`,
        unit: this.accountUnit,
        show: true
    });
  }

  unitChanged(unit) {
    let name = (unit||{}).name;
    this.units.forEach(u => u.selected = (u.id === name))
  }

  onSelectChanged(item) {
    if(!item || item.unit === this.unit) return;

    this.unit = item.unit;
    if(this.onUnitChanged)
      this.onUnitChanged(item, item.unit);
  }
}
