/** @format */

import {
  NewInstance
} from 'aurelia-framework';
import {
  Router
} from 'aurelia-router';

import groupsService from 'services/api/groupsService';

import {
  ValidationRules,
  ValidationController,
  validateTrigger
} from 'aurelia-validation';

export class AdminGroupDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController)];
  }
  constructor(_Router, _ValidationController) {
    this.groupsService = groupsService;

    this.router = _Router;
    this.validationController = _ValidationController;
    this.validationController.validateTrigger = validateTrigger.manual;

    this.group = null;
    this.loading = false;
    this.errorMessage = null;

    this.rules = ValidationRules.ensure(d => d.name)
      .required()
      .minLength(2).withMessageKey('tooShort').rules;
  }

  activate(params, cfg) {
    let {
      group
    } = cfg.settings;

    if (!group) {
      this.navigateBack();
      return;
    }

    this.group = group || {};
    this.loadGroup(params.id);
  }

  loadGroup(id) {
    if (id > 0) {
      this.loading = true;
      this.groupsService.get(id).then(group => {
        this.group = group;
        this.calcStats(group);
        this.loading = false;
      });
    }
  }

  calcStats(group = {}) {
    let getStat = x => {
      return {
        name: x,
        count: group[x] || 0
      };
    };

    this.groupStats = [
      getStat('assets'),
      getStat('drivers'),
      getStat('poi'),
      getStat('users')
    ];
  }

  onCancel() {
    this.navigateBack();
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      return this.groupsService
        .update(this.group)
        .then(_group => {
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.group,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  navigateBack() {
    setTimeout(() => this.router.navigateToRoute('admin-groups'), 0);
  }
}
