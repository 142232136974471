/** @format */
import groupsService, { Group } from 'services/api/groupsService';

export class AdminGroupsList {
  constructor() {
    this.groupsService = groupsService;

    this.gridHeaders = [{ label: 'name', sortable: true }];

    this.gridColumns = [{ type: 'text', field: 'groupName' }];

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.loading = false;
    this.onGroupSelected = this.onGroupSelected.bind(this);
    this.groups = [];
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;
    this.load();
  }

  load() {
    this.loading = true;
    this.groupsService.get().then(groups => {
      this.groups = groups;
      this.loading = false;
    });
  }

  onGroupSelected(group) {
    this.goToDetails(group);
  }

  onNewGroup() {
    let newGroup = new Group(); //
    this.goToDetails(newGroup);
  }

  goToDetails(group) {
    let route = 'admin-group-details';
    this.router.routes.find(x => x.name === route).settings = { group };
    this.router.navigateToRoute(route, { id: group.id || 'new' });
  }
}
