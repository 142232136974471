/** @format */

import {
  NewInstance,
  computedFrom
} from 'aurelia-framework';
import alertsService, {
  AlertTypes,
  AlertConfigs
} from 'services/api/alertsService';
import {
  DialogService
} from 'aurelia-dialog';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import {
  TranslationService
} from 'services/translationService';
import {
  Router
} from 'aurelia-router';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

export class AdminAlertDetails {
  static inject() {
    return [Router, TranslationService, DialogService, NewInstance.of(ValidationController)];
  }
  constructor(_Router, _TranslationService, _DialogService, _ValidationController) {
    this.router = _Router;
    this.ts = _TranslationService;
    this.validationController = _ValidationController;
    this.dialogService = _DialogService;
    this.alertsService = alertsService;

    this.loading = false;
    this.errorMessage = null;

    this.alert = null;
    this.alertTypes = AlertTypes;
    this.onTypeChanged = this.onTypeChanged.bind(this);
    this.onOwnerChanged = this.onOwnerChanged.bind(this);
  }

  @computedFrom('alert.type')
  get configType() {
    if (this.alert) {
      return AlertConfigs[this.alert.type];
    }
  }

  onTypeChanged(type) {
    this.alert.name = this.ts.getCap(type);
    this.alert.type = type;
    // clean settings
    const settings = Object.create(Object.prototype, {
      _type: {
        enumerable: false,
        value: type,
        writable: false
      }
    });
    this.alert.settings = settings;

    this.generateValidationRules();
  }

  onOwnerChanged(owner) {
    this.alert.owner = owner;
  }

  activate(params, cfg) {
    let {
      alert
    } = cfg.settings;

    if (!alert) {
      this.navigateBack();
      return;
    }

    this.alert = alert;

    //Inject type here (for now). Allosws access the alert.type inside each settings composed component
    if (this.alert.settings) {
      Object.defineProperty(this.alert.settings, '_type', {
        enumerable: false,
        value: alert.type
      });
    }

    if(!this.alert.id) {
      this.alert.name = this.ts.getCap(this.alert.type);
    }

    ValidationRules
      .ensure(d => d.name).required()
      .ensure(d => d.context).required().minItems(1).withMessageKey('nocontextAlerts')
      .ensure(d => d.channels).satisfies((value, object) => !value.filter(x => x.error).length).withMessageKey('novalidChannels')
      .ensure(d => d.settings).required().withMessageKey('nosettings')
      .on(this.alert);

    this.generateValidationRules();
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      return this.alertsService
        .updateConfiguration(this.alert)
        .then(() => {
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onDelete() {
    this.openPrompt().then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.alertsService
        .deleteConfiguration(this.alert)
        .then(success => {
          if (success) {
            this.loading = false;
            this.navigateBack();
          }
        })
        .catch(err => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    });
  }

  onCancel() {
    this.navigateBack();
  }

  onValidate() {
    return this.validationController
      .validate()
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  navigateBack() {
    setTimeout(() => this.router.navigateToRoute('admin-alerts'), 0);
  }

  openPrompt() {
    const title = 'delete_alert';
    const question = true;
    const message = 'delete_alert_message';
    const confirm = 'delete';

    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  generateValidationRules() {
    //clean validation rules
    Array.from(this.validationController.objects).forEach(([key]) => {
      this.validationController.removeObject(key);
    });

    if (AlertConfigs[this.alert.type] === 'zonealert') {
      if (!this.alert.settings.zoneCollection) {
        this.alert.settings.zoneCollection = []
      }
      let rules = ValidationRules.ensure(d => d.zoneCollection).minItems(1).withMessageKey('required').rules;
      this.validationController.addObject(this.alert.settings, rules);
    } else if (AlertConfigs[this.alert.type] === 'poialert' && this.alert.type !== AlertTypes.doorsensor) {
      if (!this.alert.settings.poiCollection) {
        this.alert.settings.poiCollection = []
      }
      let rules = ValidationRules.ensure(d => d.poiCollection).minItems(1).withMessageKey('required').rules;
      this.validationController.addObject(this.alert.settings, rules);
    }
  }
}
