/** @format */

import assetsService, { Asset } from 'services/api/assetsService';
import uiService from 'services/uiService';

export class AdminAssetsList {
  constructor() {
    this.assetsService = assetsService;
    this.uiService = uiService;

    this.gridHeaders = [
      { label: '' },
      { label: 'name', sortable: true },
      { label: 'driver', sortable: true, sortField: 'driverName' }
    ];

    this.gridColumns = [
      { type: 'icon', field: 'assetIcon', cssClass: 'icon' },
      { type: 'text', field: 'name' },
      { type: 'text', field: 'driverName' }
    ];

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.assetList = [];
    this.route = {};

    // this.selectedItem = null;
    this.error = null;

    this.isLoading = false;
    this.onAssetListSelected = this.onAssetListSelected.bind(this);
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.assetsService.getAll(true).then(assets => {
      this.assetList = assets.map(a => {
        a.assetIcon = this.getAssetIcon(a.assetType);
        return a;
      });
      this.isLoading = false;
    });
  }

  onAssetListSelected(item) {
    // this.selectedItem = item;
    this.goToDetails(item);
  }

  newAsset() {
    // this.selectedItem =
    this.goToDetails(new Asset({ assetType: 'vehicle' }));
  }

  goToDetails(asset) {
    let route = 'admin-asset-details';
    this.router.routes.find(x => x.name === route).settings = { asset };
    this.router.navigateToRoute(route, { id: asset.id || 'new' });
  }

  getAssetIcon(assetType) {
    let types = {
      vehicle: 'fi-car',
      mobile: 'fi-smartphone'
    };
    return types[assetType] || types.vehicle;
  }
}
