import { DateTimeUtils as DTU } from '@fonix/web-utils';

export class OutofhoursSettings {

  constructor() {
    this.settings = {};
  }
  

  activate(model) {
    this.settings = model;
    if(this.settings) {
      
      if(!this.settings.dayStart || !this.settings.dayEnd) {
        //get local 9:00 and 18:00 to get the utc correspondent 
        let localStart = DTU.add(DTU.startOf(new Date()), 9, 'hours');
        let localEnd = DTU.add(localStart, 9, 'hours');
        
        this.settings.dayStart = DTU.format(localStart, 'TIME');
        this.settings.dayEnd = DTU.format(localEnd, 'TIME');
      }
    }
  }
    
}
