import { inject } from 'aurelia-framework';
import { TranslationService } from 'services/translationService';

@inject(TranslationService)
export class ContinousdrivingSettings {
  constructor(_TranslationService) {
    this.settings = {};
    
    let labelFn = (n) => { return `${n} ${_TranslationService.getCap('hours')}` };

    let optionFn = (n, val) => { return { id: n, name: labelFn(n), value: val } };
    this.durations = [
      optionFn(3, '03:00'),
      optionFn(3.5, '03:30'),
      optionFn(4, '04:00'),
      optionFn(4.5, '04:30'),
      optionFn(5, '05:00'),
      optionFn(5.5, '05:30')
    ];
    this.onDurationChanged = this.onDurationChanged.bind(this);
  }

  activate(model) {
    this.settings = model;

    if (this.settings) {
      let dur = this.durations.find(x=> x.value === this.settings.duration) || this.durations[0];
      dur.selected = true;
      //
      this.onDurationChanged(dur);
    }
  }

  onDurationChanged(option) {
    if(option) {
      this.settings.duration = option.value;
    }
  }
}
