/** @format */
import {
  NewInstance
} from 'aurelia-framework';
import {
  Router
} from 'aurelia-router';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';

import {
  ConvertUtils
} from '@fonix/web-utils';

import assetsService, {
  AssetTypes,
  FuelTypes,
  VehicleTypes
} from 'services/api/assetsService';
import driversService from 'services/api/driversService';

const NO_DRIVER_ID = 0;

export class AdminAssetDetails {
  static inject() {
    return [NewInstance.of(ValidationController), Router];
  }

  constructor(_ValidationController, _Router) {
    this.router = _Router;
    this.validationController = _ValidationController;

    //
    this.assetsService = assetsService;
    this.driversService = driversService;
    //

    this.asset = null;

    this.drivers = [];

    this.loading = false;
    this.errorMessage = null;

    this.assetTypes = AssetTypes;
    this.fuelTypes = FuelTypes;
    this.vehicleTypes = VehicleTypes;

    this.rules = ValidationRules.ensure(d => d.name).required();

    //
    this.editOdometerUnit = null;
    this.onOdometerUnitChanged = this.onOdometerUnitChanged.bind(this);

    this.fuelUnit = ConvertUtils.getUnit('fuelConsumption');

    this.onSave = this.onSave.bind(this);
  }

  activate(params, cfg) {
    let {
      asset
    } = cfg.settings;

    if (!asset) {
      this.navigateBack();
      return;
    }

    this.asset = asset;
    this.loadData(params.id);
  }

  loadData(assetId) {
    let qDrivers = this.loadDrivers();

    this.loadAsset(assetId).then(_asset => {
      this.loading = false;
      let driverId = _asset ? _asset.driverId : NO_DRIVER_ID;
      qDrivers.then(() => this.selectDriver(driverId));
    });
  }

  loadAsset(id) {
    if (id > 0) {
      this.loading = true;
      return this.assetsService
        .getById(id)
        .then(asset => {
          this.asset = asset;
          this.editOdometerUnit = asset.odometerUnit;
          return asset;
        })
        .catch(() => {
          // console.log('error loading asset', err);
          this.navigateBack();
        });
    }

    return Promise.resolve(this.asset);
  }

  loadDrivers() {
    return this.driversService.get().then(drivers => {
      let _drivers = drivers || [];
      _drivers.unshift({
        id: NO_DRIVER_ID,
        name: 'No driver',
        default: true
      });
      this.drivers = _drivers;

      return _drivers;
    });
  }

  selectDriver(driverId) {
    this.drivers = this.drivers.map(d => {
      d.selected = d.id === (driverId || NO_DRIVER_ID);
      return d;
    });
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      let driver = this.drivers.find(x => x.selected) || {
        id: NO_DRIVER_ID
      };
      this.asset.driverId = driver.id;

      return this.assetsService
        .update(this.asset)
        .then(_asset => {
          this.loading = false;
          _asset.driverName = driver.id > 0 ? driver.name : null;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onCancel() {
    this.navigateBack();
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.asset,
        rules: this.rules.rules
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onOdometerUnitChanged(item, unit) {
    this.editOdometerUnit = unit;
    this.asset.odometerUnit = item.default ? {} : unit;
  }

  navigateBack() {
    setTimeout(() => this.router.navigateToRoute('admin-assets'), 0);
  }
}
