/** @format */

import { zonesService } from 'services/api/areasService';

export class ZonealertSettings {
  constructor() {
    this.zonesService = zonesService;

    this.settings = {};
    this.zones = [];
    this.selectedZones = [];
    this.onListChanged = this.onListChanged.bind(this);
  }

  activate(model) {
    this.settings = model;

    this.zonesService.get().then(zones => {
      this.zones = zones;
      if (this.settings) {
        this.selectedZones = this.settings.zoneCollection;
      }
    });
  }

  onListChanged(items = []) {
    let ids = items.map(x => {
      return x.id;
    });
    this.settings.zoneCollection = ids;
  }
}
