/** @format */

import { computedFrom } from 'aurelia-framework';
import poiService from 'services/api/areasService';

export class PoialertSettings {
  constructor() {
    this.poiService = poiService;

    this.time = '10:00';
    this.label = 'poi';
    this.settings = {};
    this.pois = [];
    this.selectedPois = [];
    this.onListChanged = this.onListChanged.bind(this);
  }

  @computedFrom('settings._type')
  get isDoorSensor() {
    if(this.settings._type === 'doorsensor') {
      this.label = 'trusted_locations'
      return true;
    } else {
      this.label = 'poi'
      return false;
    }
  }

  activate(model) {
    this.settings = model;

    this.poiService.get().then(pois => {
      this.pois = pois;
      this.selectedPois = (this.settings && this.settings.poiCollection) || [];
    });
  }

  onDurationChanged(option) {
    if (option) {
      this.settings.duration = option.value;
    }
  }

  onListChanged(items = []) {
    let selectedIds = items.map(x => {
      return x.id;
    });
    this.settings.poiCollection = selectedIds;
  }
}