import { inject, computedFrom } from 'aurelia-framework';
import { ConvertUtils } from '@fonix/web-utils';

export class HarshEventSettings {
  constructor() {    
    this.harshevent = {};

    this.eventTypes = {
      acceleration: 'acceleration',
      deceleration: 'deceleration'
    };

    this.severities = {
      normal: 'normal',
      high: 'high',
      critical: 'critical'
    };

    this.defaults = {
      eventType: 'deceleration',
      severity: 'high'
    }

    this.onInputChanged = this.onInputChanged.bind(this);
  }

  activate(model) {
    this.harshevent = model;

    if (this.harshevent) {
      
      //values must be set for binding to work, objects cannot be undefined
      this.harshevent.eventType = model.eventType || this.defaults.eventType;
      this.harshevent.severity = model.severity || this.defaults.severity;       
    }
  }

  //TODO: Refactor this into either a value converter (or a custom attibute that changed the elem value)
  onInputChanged(e) {
    let field = e.target.name;
    if(!this.harshevent[field] && (this.harshevent[field] !== 0)) {
      this.harshevent[field] = type ? this.defaults[type][field] : this.defaults[field];
    }
  }
}
