import { inlineView } from 'aurelia-framework'
import PLATFORM from 'aurelia-pal';

import './admin-alerts-page.scss';

@inlineView('<template><router-view containerless></router-view></template>')
export class AdminAlertsPage {

  configureRouter(config,router) {
    config.map([{
      route: '',
      name: 'admin-alerts',
      title: '',
      moduleId: PLATFORM.moduleName('./admin-alerts-list')
      },{
        route: ':id',
        name: 'admin-alert-details',
        title: 'Alert details',
        moduleId: PLATFORM.moduleName('./admin-alert-details')
      }
    ])
  }
}
