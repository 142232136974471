import { inject, observable } from 'aurelia-framework';
import { TranslationService } from 'services/translationService';

@inject(TranslationService)
export class PanicSettings {
  @observable videoRetrieval;

  constructor(_TranslationService) {
    this.settings = {};
    this.videoRetrieval = false;

    let labelFn = (n) => {
      return `${n} ${_TranslationService.getCap('seconds')}`
    };

    this.durations = [
      { id: 40, name: labelFn(40), value: 40 }
    ];
    this.onDurationChanged = this.onDurationChanged.bind(this);
  }

  activate(model) {
    this.settings = model;

    if (this.settings) {
      this.settings.videoDownloadEnabled ? this.videoRetrieval = this.settings.videoDownloadEnabled : '';

      let dur = this.durations.find(x => x.value === this.settings.videoDownloadLength) || this.durations[0];
      dur.selected = true;
      //
      this.onDurationChanged(dur);
    }
  }

  videoRetrievalChanged() {
    if (!this.videoRetrieval && this.settings.videoDownloadLength) {
      this.settings.videoDownloadLength = undefined;
    }
    this.settings.videoDownloadEnabled = this.videoRetrieval;
  }

  onDurationChanged(option) {
    if (option) {
      this.settings.videoDownloadLength = option.value;
    }
  }
}
