import { inject, computedFrom } from 'aurelia-framework';
import { ConvertUtils } from '@fonix/web-utils';

export class OverspeedSettings {
  constructor() {    
    this.overspeed = {};

    this.limitTypes = {
      fixed: 'fixed',
      roadspeed: 'roadspeed'
    };

    this.marginTypes = {
      fixed: 'fixed',
      percentage: 'percentage'
    };

    this.defaults = {
      limitValue: 120,
      margin: {
        fixed: 120,
        percentage: 10
      }
    }

    this.speedUnit = null;
    this.speedUnitName = 'mph';

    this.onInputChanged = this.onInputChanged.bind(this)
    this.onMarginValueChanged = this.onMarginValueChanged.bind(this);
    this.onMarginTypeChanged = this.onMarginTypeChanged.bind(this);
  }

  @computedFrom('overspeed.limitType')
  get showFixedValue() {
    return this.overspeed.limitType == this.limitTypes.fixed;
  }

  @computedFrom('overspeed.marginType')
  get showFixedMargin() {
    return this.overspeed.marginType == this.marginTypes.fixed;
  }

  activate(model) {
    this.overspeed = model;

    if (this.overspeed) {

      this.speedUnit = ConvertUtils.getUnit('speed');
      this.speedUnitName = this.speedUnit.name || 'mph';
      
      //values must be set for binding to work, objects cannot be undefined
      this.overspeed.limitType = model.limitType || this.limitTypes.fixed;      
      this.overspeed.limitValue = this.getValueOrDefault(model.limitValue, this.defaults.limitValue);

      this.overspeed.marginType = model.marginType || this.marginTypes.percentage;
      this.overspeed.marginValue = this.getValueOrDefault(model.marginValue, this.defaults.margin[this.overspeed.marginType]);
      
    }
  }

  //TODO: Refactor this into either a value converter (or a custom attibute that changed the elem value)
  onInputChanged(e) {
    let field = e.target.name;
    if(!this.overspeed[field] && (this.overspeed[field] !== 0)) {
      this.overspeed[field] = type ? this.defaults[type][field] : this.defaults[field];
    }
  }

  //find better way to do this
  onMarginValueChanged(e) {
    if(!this.overspeed.marginValue && (this.overspeed.marginValue !== 0)) {
      this.overspeed.marginValue = this.defaults.margin[this.overspeed.marginType];
    }
  }

  onMarginTypeChanged(value) {
    // console.log('onMarginTypeChanged', va)
    this.overspeed.marginValue = this.defaults.margin[value] || this.overspeed.marginValue;
  }

  getValueOrDefault(value, def) {
    return this.hasValue(value) ? value : def;
  }

  //js and it's truthiness..
  hasValue(value) {
    return value || value == 0;
  }
}
