/** @format */
import driversService, { Driver } from 'services/api/driversService';

export class AdminDriversList {
  constructor() {
    this.driversService = driversService;
    this.drivers = [];

    this.gridHeaders = [
      { label: 'name', sortable: true },
      { label: 'phoneNumber', sortable: true }
    ];
    this.gridColumns = [
      { type: 'text', field: 'name' },
      { type: 'text', field: 'phoneNumber' }
    ];
    
    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.onDriverSelected = this.onDriverSelected.bind(this);
    this.onNewDriver = this.onNewDriver.bind(this);
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;
  }

  attached() {
    this.loadDrivers();
  }

  loadDrivers() {
    this.loading = true;
    this.driversService.get().then(drivers => {
      this.drivers = drivers;
      this.loading = false;
    });
  }

  onDriverSelected(driver) {
    this.goToDetails(driver);
  }

  onNewDriver() {
    this.goToDetails(new Driver());
  }

  goToDetails(driver) {
    let route = 'admin-driver-details';
    this.router.routes.find(x => x.name === route).settings = { driver };
    this.router.navigateToRoute(route, { id: driver.id || 'new' });
  }
}
