/** @format */

import {
  NewInstance,
  computedFrom
} from 'aurelia-framework';
import {
  ValidationRules,
  ValidationController
} from 'aurelia-validation';
import driversService, {
  DriverApplications
} from 'services/api/driversService';
import userService from 'services/api/userService';
import uiService from 'services/uiService';

import {
  DialogService
} from 'aurelia-dialog';
import {
  Router
} from 'aurelia-router';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

import {
  DriverConsentDialog
} from './driver-consent-dialog/driver-consent-dialog';

import './admin-driver-details.scss';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

const DLC_STATUS = {
  GRANTED: 'granted',
  PENDING: 'pending',
  REVOKED: 'revoked'
};

export class AdminDriverDetails {
  static inject() {
    return [Router, DialogService, NewInstance.of(ValidationController)];
  }
  constructor(_Router, _DialogService, _ValidationController) {
    this.dialogService = _DialogService;
    this.router = _Router;
    this.validationController = _ValidationController;

    this.driversService = driversService;
    this.userService = userService;
    this.uiService = uiService;

    this.accountHasDLC = uiService.ex_hasDLC;

    this.driver = null;
    this.driverUser = null;
    this.loading = false;
    this.errorMessage = null;
    this.applicationsList = DriverApplications;
    this.activeTab = 'profile';

    //DVLA
    this.dvlaError = null;

    this.rules = ValidationRules.ensure(d => d.name)
      .required()
      .minLength(2)
      .withMessageKey('tooShort')
      .ensure(d => d.contractedHours)
      .satisfies(value => !value || value <= 120).rules;

    this.onSave = this.onSave.bind(this);
    this.onContracHrsChange = this.onContracHrsChange.bind(this);
  }

  @computedFrom('driver', 'driver.dlc')
  get dlc() {
    return this.driver && this.driver.dlc;
  }

  @computedFrom('dlc', 'dlc.consent')
  get dlcGranted() {
    if (!this.dlc) return;

    return this.dlc.consent && this.dlc.consent.status === DLC_STATUS.GRANTED;
  }

  @computedFrom('dlc', 'dlc.consent')
  get dlcPending() {
    if (!this.dlc) return;

    return this.dlc.consent && this.dlc.consent.status === DLC_STATUS.PENDING;
  }

  @computedFrom('dlc', 'dlc.consent')
  get dlcRevoked() {
    if (!this.dlc) return;

    return this.dlc.consent && this.dlc.consent.status === DLC_STATUS.REVOKED;
  }

  @computedFrom('dlc', 'dlc.consent')
  get canRequestConsent() {
    return (
      !this.dlc ||
      !this.dlc.consent ||
      this.dlc.consent.status === DLC_STATUS.REVOKED
    );
  }

  @computedFrom('dlc', 'dlc.consent')
  get canRequestLicence() {
    if (!this.dlc || !this.dlc.consent || !this.dlcGranted) return false;

    return true;
  }

  @computedFrom('user.accountSettings')
  get DLCSettings() {
    if (!this.user || !this.user.accountSettings || !this.user.accountSettings.ex_thirdparty_dlc) return false;
    return this.user.accountSettings.ex_thirdparty_dlc;
  }

  @computedFrom('DLCSettings')
  get automaticRenewals() {
    if (!this.DLCSettings || this.DLCSettings.properties) return false;

    return this.DLCSettings.properties.automaticRenewals;
  }

  activate(params, cfg) {
    let {
      driver
    } = cfg.settings;

    if (driver) {
      this.driver = driver;
    }
    this.loadDriver(params.id);
  }

  loadDriver(id) {
    if (id > 0) {
      this.loading = true;
      this.driversService.get(id, this.accountHasDLC).then(driver => {
        this.driver = driver;
        if (this.driver.userId) {
          this.userService.getUsers(this.driver.userId).then(user => {
            this.driverUser = user;
            for (let index = 0; index < this.applicationsList.length; index++) {
              this.applicationsList[index].selected = (this.driver.applications.indexOf(this.applicationsList[index].id) > -1) ? true : false;
            }
            this.loading = false;
          }).catch(e => {
            this.loading = false;
          });
        } else {
          for (let index = 0; index < this.applicationsList.length; index++) {
            this.applicationsList[index].selected = false;
          }
          if (this.driver.dlc && this.driver.dlc.driver && this.driver.dlc.driver.emailAddress) {
            this.driverUser = {
              email: this.driver.dlc.driver.emailAddress
            };
          }
          this.loading = false;
        }
      });
    }
  }

  onContracHrsChange(e) {
    this.driver.contractedHours = Math.min(120, Math.max(0, e.target.value));
  }

  onCancel() {
    this.navigateBack();
  }

  onSave(cancelNav) {
    
    return this.validate()
      .then(success => {
        if (!success) throw false;

        //TODO: phoneNumberObj must be null when both .number && .coutryCode is empty
        //server will complain. if object exists it must be filled with information.
        //change phone-number to deal with object directly and not seperate fields (optionally)
        if (this.driver.phoneNumberObj && !this.driver.phoneNumberObj.number) {
          this.driver.phoneNumberObj = null;
        }

        this.loading = true;
        return this.driversService.update(this.driver);
      })
      .then(driver => {
        this.driver = driver;
        this.loading = false;

        if (!cancelNav) this.navigateBack();

        return driver;
      })
      .catch(err => {
        this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
        
        this.loading = false;

        //bubble exception
        return Promise.reject(err);
      });
  }

  onDelete() {
    this.openPrompt('delete_driver', true, 'delete_driver_message', 'delete').then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.driversService

        .delete(this.driver.id)
        .then(() => {
          this.driver.deleted = true;
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.errorMessage = err.message;
          
          this.loading = false;
        });
    });
  }

  validate() {
    return this.validationController
      .validate({
        object: this.driver,
        rules: this.rules
      })
      .then(result => result.valid)
      .catch(() => {
        // console.log('onValidate:err', err);
        return false;
      });
  }

  requestDLC() {
    this.dialogService
      .open({
        viewModel: DriverConsentDialog,
        model: this.driver
      })
      .whenClosed(result => {
        // console.log('dvlaCheck',result)
        if (!result.wasCancelled) {
          let driver = result.output;

          this.driver = driver;
          // if (driver && driver.dlc) {
          //   this.driver.dlc = driver.dlc
          // }
        }
      });
  }

  checkLicenceDLC() {
    this.loading = true;
    return this.driversService
      .checkLicence(this.driver.id)
      .then(driver => {
        //
        // console.log('check licence consent',driver)
        this.driver = driver;
        this.loading = false;
      })
      .catch(err => {
        this.dvlaError =
          (err && err.message) ||
          'There were errors performing DVLA check. Please make sure you filled all the information.';
        this.loading = false;
        //
        //report dvla validations
      });
  }

  onRevokeConsentClick(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    this.loading = true;
    this.dvlaError = null;

    this.driversService
      .revokeConsent(this.driver.id)
      .then(driver => {
        this.driver = driver;
        this.loading = false;
      })
      .catch(err => {
        this.dvlaError =
          (err && err.message) ||
          'There were errors performing DVLA check. Please make sure you filled all the information.';
        this.loading = false;
      });

    return false;
  }

  onResendConsent(e) {
    this.openPrompt('resend_consent', false, 'resend_consent_message', 'resend').then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.dvlaError = null;

      this.driversService.resendConsent(this.driver.id).then(() => {
        this.loading = false;
      }).catch(err => {
        this.dvlaError =
          (err && err.message) ||
          'There were errors performing DVLA check. Please make sure you filled all the information.';
        this.loading = false;
      });
    });
  }

  onDeleteConsent(e) {
    this.openPrompt('delete_consent', true, 'delete_consent_message', 'clear').then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.dvlaError = null;

      this.driversService.deleteConsent(this.driver.id).then(() => {
        this.driver.dlc = null;
        this.loading = false;
      }).catch(err => {
        this.dvlaError =
          (err && err.message) ||
          'There were errors performing DVLA check. Please make sure you filled all the information.';
        this.loading = false;
      });
    });
  }

  openPrompt(title, question, message, confirm) {
    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  navigateBack() {
    setTimeout(() => this.router.navigateToRoute('admin-drivers'), 0);
  }

  getEndorsementExpirationNotes(endorsement) {
    if (endorsement.expires && DTU.isBefore(endorsement.expires, DTU.today())) {
      return 'expired';
    } else if (endorsement.isDisqualifying && endorsement.disqualificationEnd && DTU.isAfter(endorsement.disqualificationEnd, DTU.today())) {
      return 'disqualified_until';
    } else {
      return null;
    }
  }

  onAppToggleEnabled(ev, application) {
    if (application.selected) {
      this.driversService.grantAccessApp(this.driver.id, this.driverUser.email, application.id).then(response => {
        if (!this.driver.applications) {
          this.driver.applications = [];
        }
        var index = this.driver.applications.indexOf(application.id);
        if (index == -1) {
          this.driver.applications.push(application.id);
        } else {
          ev.target.value = !application.selected;
          application.selected = !application.selected;
          this.errorMessage = 'Inserted email is already in use or you dont have permissions to grant access to the driver.'
          
        }
      }).catch(e => {
        ev.target.value = !application.selected;
        application.selected = !application.selected;
        this.errorMessage = 'Inserted email is already in use or you dont have permissions to grant access to the driver.'
        
      })
    } else {
      this.driversService.revokeAccessApp(this.driver.id, application.id).then(response => {
        if (!this.driver.applications) {
          this.driver.applications = [];
        }

        var index = this.driver.applications.indexOf(application.id);
        if (index > -1) {
          this.driver.applications.splice(index, 1);
        } else {
          ev.target.value = !application.selected;
          application.selected = !application.selected;
          this.errorMessage = 'Inserted email is already in use or you dont have permissions to grant access to the driver.'
          
        }
      }).catch(e => {
        ev.target.value = !application.selected;
        application.selected = !application.selected;
        this.errorMessage = 'Inserted email is already in use or you dont have permissions to grant access to the driver.'
        
      })
    }
  }

  @computedFrom('driver')
  get driverAvailable() {
    return (this.driver) ? true : false;
  }

  @computedFrom('driverUser.email')
  get isDriverEmailValid() {
    return (this.driverUserEmailEl && this.driverUserEmailEl.validity.valid) ? true : false;
  }

  @computedFrom('driver', 'driver.applications')
  get isDriverAppsEnabled() {
    return (this.driver && this.driver.applications && this.driver.applications.length > 0) ? true : false;
  }

  changeTabs(event) {
    if (event.detail && event.detail.activeTab) {
      this.activeTab = event.detail.activeTab;
    }
  }
}
