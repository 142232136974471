/** @format */

import alertsService, { AlertConfiguration } from 'services/api/alertsService';
import { TranslationService } from 'services/translationService';

import './admin-alerts-page.scss';

export class AdminalertsList {
  static inject() {
    return [TranslationService];
  }

  constructor(_TranslationService) {
    this.ts = _TranslationService;
    //
    this.alertsService = alertsService;
    //

    this.gridHeaders = [
      { label: '' },
      { label: 'name', sortable: true },
      { label: 'type', sortable: true }
    ];
    this.gridColumns = [
      { type: 'icon', field: 'icon', cssClass: 'icon' },
      {
        type: 'text',
        field: 'name',
        translate: true,
        translateOptions: { userkey: true }
      },
      { type: 'text', field: 'type', translate: true }
    ];

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.alertsConfigurations = [];
    this.loading = false;

    this.onAlertSelected = this.onAlertSelected.bind(this);
  }

  activate(params, config) {
    this.route = config;
    this.router = this.route.navModel.router;
    this.loadalerts();
  }

  loadalerts() {
    this.loading = true;
    this.alertsService.getConfigurations().then(alerts => {
      this.alertsConfigurations = alerts;
      this.loading = false;
    });
  }

  onAlertSelected(alert) {
    this.goToDetails(new AlertConfiguration(alert));
  }

  newConfiguration() {
    this.goToDetails(new AlertConfiguration({ type: 'continousdriving' }));
  }

  goToDetails(alert) {
    let route = 'admin-alert-details';
    this.router.routes.find(x => x.name === route).settings = { alert };
    this.router.navigateToRoute(route, { id: alert.id || 'new' });
  }
}
