import { inject } from 'aurelia-framework';
import { TranslationService } from 'services/translationService';

@inject(TranslationService)
export class ExcessiveidleSettings {
  constructor(_TranslationService) {
    this.settings = {};
    
    let labelFn = (n) => { return `${n} ${_TranslationService.getCap('minutes')}` };

    this.durations = [
      { id: 2, name: labelFn(2), value: '02:00' },
      { id: 5, name: labelFn(5), value: '05:00' },
      { id: 10, name: labelFn(10), value: '10:00' }
    ];
    this.onDurationChanged = this.onDurationChanged.bind(this);
  }

  activate(model) {
    this.settings = model;

    if (this.settings) {
      let dur = this.durations.find(x=> x.value === this.settings.duration) || this.durations[1];
      dur.selected = true;
      //
      this.onDurationChanged(dur);
    }
  }

  onDurationChanged(option) {
    if(option) {
      this.settings.duration = option.value;
    }
  }
}
