import PLATFORM from 'aurelia-pal';
import {
  inlineView
} from 'aurelia-framework'

import './admin-assets-page.scss';

@inlineView('<template><router-view containerless></router-view></template>')
export class AdminAssetsPage {

  configureRouter(config, router) {
    config.map([{
      route: '',
      name: 'admin-assets',
      title: '',
      moduleId: PLATFORM.moduleName('./admin-assets-list')
    }, {
      route: ':id',
      name: 'admin-asset-details',
      title: 'Asset details',
      moduleId: PLATFORM.moduleName('./admin-asset-details')
    }])
  }
}
