import { inlineView } from 'aurelia-framework'
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class AdminPoiPage {

  configureRouter(config,router) {
    config.map([{
      route: '',
      name: 'admin-groups',
      title: '',
      moduleId: PLATFORM.moduleName('./admin-groups-list')
      },{
        route: ':id',
        name: 'admin-group-details',
        title: 'Group details',
        moduleId: PLATFORM.moduleName('./admin-group-details')
      }
    ])
  }
}
