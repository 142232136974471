import { bindable } from 'aurelia-framework';


const TYPES = {
  'email' : 'email',
  'sms': 'textMessage'
};

export class AlertConfigChannels {

  @bindable channels;

  constructor() {
    this.channels = [];

    this.emails = [];
    this.sms = '';

    this.onEmailsChanged = this.onEmailsChanged.bind(this);
    this.onSMSChanged = this.onSMSChanged.bind(this);
  }

  channelsChanged(newChannels,oldChannels) {
    this.emails = this.getChannel(TYPES.email).recipients
    this.sms = (this.getChannel(TYPES.sms).recipients||[]).join(',');
  }
  
  //update chanels by means of two way binding
  updateChannel(type, value) {
    this.getChannel(type).recipients = !!value.length ? value.split(',') : [];
  }

  getChannel(type) {
    this.channels = this.channels || [];
    let channel = this.channels.find(x => x.type === type);
    if(!channel) {
      channel = { type, recipients: [] };
      this.channels.push(channel);
    }
    return channel;
  }

  onEmailsChanged(emails) {
    this.updateChannel(TYPES.email, emails)
  }

  onSMSChanged(sms) {
    this.updateChannel(TYPES.sms, sms)
  }
}
