import { bindable } from 'aurelia-framework';

export class AlertConfigContext {

  @bindable contextType;
  @bindable context;

  constructor() {
    this.selectedIds = [];
    this.context = [];
    this.types = {
      assets : 'assets'
    };

    this.onAssetListChanged = this.onAssetListChanged.bind(this);
  }

  onAssetListChanged(assets) {
    let ids = (assets||[]).map(x=> { return x.id });
    this.context = ids;
    //todo maybe skip selectedIds assignment on contextChange for perf
  }

  contextChanged(context) {
    this.selectedIds = context;
  }
}
