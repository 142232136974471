import { bindable, inject } from 'aurelia-framework';

export class AlertTimePeriod {

  @bindable period;
  @bindable triggerInside;

  constructor() {  
    this.enabled = false;
  }

  periodChanged(value) {
    this.enabled = !!value;

  }

  onEnabledChecked(e) {
    let checked = (e && e.target && e.target.checked);
    
    if(!checked) {
      this.triggerInside = false;
      this.period = null;
      return;
    }
    
    this.period = this.period || {
      start: '08:00',
      end: '16:00'      
    }
  }
}
